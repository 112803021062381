import React from 'react'
import { Flex, Box, Link, Heading, Text } from 'theme-ui'
import HardCodedBoxes from './HardCodedBoxes'
import componentOptionsSelector from '../../../helpers/componentOptionsSelector'
import generateLink from '../../../helpers/generateLink'
import ContentBox from './ContentBox'

export default function ContentBoxes(props) {
  const {
    options,
    variantName,
    city,
    // configData,
    // businessData,
    poweredImages,
    isMultiLocationSite,
    locationIndex,
    allGeneral
  } = props

  const usingStories = options?.storyId?.[locationIndex] ? true : false
  const storyIds = options.storyId?.[locationIndex]
  const storyTag = options.storyTag?.[locationIndex]

  const storyObjects = storyIds?.map(storyId => {
    return componentOptionsSelector(options, locationIndex, storyId, allGeneral, storyTag)
  })

  const StoryBoxes = () => {
    return storyObjects.map(({ titles, subtitles, textType, texts, images, ctaNames, ctaLinks }) => {
      const ctaLink = ctaLinks ? generateLink(ctaLinks, isMultiLocationSite, city) : ''

      return (
        <ContentBox
          variantName={variantName}
          ctaLink={ctaLink}
          ctaName={ctaNames}
          image={images?.[0]}
          title={titles}
          subtitle={subtitles}
          text={texts}
          textType={textType}
          locationIndex={locationIndex}
          poweredImages={poweredImages}
        />
      )
    })
  }
  return (
    <Flex variant={`${variantName}.contentBoxes`} className="contentBoxes">
      {usingStories ? StoryBoxes() : <HardCodedBoxes {...props} />}
    </Flex>
  )
}

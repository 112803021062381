export default function filterAlbumsToShow(albumsList, albumsToShow) {
  if (!albumsToShow || albumsToShow?.length === 0) return albumsList

  return albumsList.filter((album, index) => {
    return albumsToShow
      .map(showAlbum => album?.name.trim().toLowerCase() === showAlbum.trim().toLowerCase())
      .includes(true)
  })
}

// Used on the Gallery Page to Filter out any albums you want to remove
// I.e  albumsList = [{name:shouts,photos:[{},{}]},{name:food,photos:[{},{}]},{name:drinks,photos:[{},{}]}]
// I.e  albumsToRemove = ["shouts","food"],
// returns [{name:drinks,photos:[{},{}]}]

// first loops through the albumList with filter remove any of the albums names that
// match anything in the Albums to remove
// it is not case senstive
